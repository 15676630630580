header {
  height: 135vh;
  padding-top: 7rem;
  overflow: hidden;
  margin: 0 1rem;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* CTA*/

.CTA {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* Socials*/
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  transition: transform 0.3s ease;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* Images*/

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 0rem 1.5rem 0rem 1.5rem;
}
.main_img{
   background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
}
.mask{
-webkit-mask-image: linear-gradient(black,transparent);
mask-image: linear-gradient(black, transparent);

}
.my-img{
margin-top: -7rem;
}
/* scroll */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 600px) {
  header {
    height: 120vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }

  .header__container h1 {
    font-size: 1.8rem;
  }

  .text-light {
    margin-top: 1rem;
  }
  .CTA {
    font-size: 12px;
  }
}
