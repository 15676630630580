.experience_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience_container > div {
  background: var(--color-bg-varaint);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  border-color: var(--color-primary-variant);
}

.experience_container > div:hover {
  background: transparent;
  border-color: var(--hover);
  cursor: default;
}

.experience_container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.expe_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
}

.expe_details {
  display: flex;
  gap: 1rem;
}

.expe-icons {
  margin-top: 6px;
  color: var(--hover);
}

@media screen and (max-width: 1024px) {
  /*#exper {
    padding-top: 89px;
  }*/

  .experience_container {
    grid-template-columns: 1fr;
  }

  .experience_container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .expe_content {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
 /* #exper {
    padding-top: 32rem;
  }*/
  .experience_container {
    margin-top: 5rem;
    gap: 1rem;
  }
  .experience_container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
  .marketing {
    display: flex;
    flex-direction: column;
  }
  .market {
    display: flex;
    flex-direction: column;
  }
}
