.testimonials_container {
  width: 30%;
  padding-bottom: 4rem;
}

.client_avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.35rem solid var(--color-primary-variant);
}
.testimonial {
  background: transparent;
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
  border: 1px solid var(--hover);
}

.swiper-pagination-bullet-clickable .swiper-pagination-bullet {
  background: var(--hover);
}


.client_review {
  color: var(--color-light);
  display: block;
  font-weight: 300;
  width: 80%;
  margin: 0.8rem auto 0;
}

@media screen and (max-width: 1024px) {
  .container.testimonials_container {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
/*  #testimonials {
    margin-top: 136rem;
  }*/
  .container.testimonials_container {
    width: var(--container-width-sm);
  }

  .client_review {
    width: var(--container-width-sm);
  }
}
