.container_portfolio {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.article_item {
  background: var(--color-bg-varaint);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  border-color: var(--color-bg-varaint);
}
small{
margin-bottom: 1rem;

}
.article_item:hover {
  border-color: var(--hover);
  background: transparent;
}

.div_port-image {
  border-radius: 1.5rem;
  overflow: hidden;
  max-height: 300px;
}

.article_item h3 {
  margin: 1.2rem 0 2rem;
}
.imgheight{

      min-height: 170px;
}
.item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
    margin-top: 1rem;
}

@media screen and (max-width: 1024px) {
  .container_portfolio {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    /* margin-top: 10rem; */
  }
}

@media screen and (max-width: 600px) {
 /* #portfolio {
    margin-top: 140rem;
  }*/
  .container_portfolio {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
